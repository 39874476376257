@import 'styles/includes';

.Footer {
    $root: &;
    margin: 6rem 0 0;

    @include media(m) {
        margin: 12rem 0 0;
    }

    &__Container {
        padding: 4rem 0;
        background-color: $black;
        border: 2rem solid $white;
    }

    &__Inner {
        margin-left: auto;
        margin-right: auto;
        padding: 0 1.5rem;
        width: 100%;
        max-width: 129rem;

        @include media(l) {
            padding: 0 1.5rem;
        }
    }

    &__Top {
        display: grid;
        gap: 4rem;

        @include media(m) {
            grid-template-columns: size(4) size(7);
            gap: size(1);
        }

        @include media(xl) {
            grid-template-columns: size(3) size(6);
            gap: size(3);
        }
    }

    &__TopRight {
        display: grid;
        gap: 4rem;

        @include media(m) {
            text-align: left;
            grid-template-columns: size(6) size(6);
            gap: 0;
        }

        @include media(l) {
            grid-template-columns: size(8) size(4);
        }

        @include media(xl) {
            grid-template-columns: size(6) size(6);
        }
    }

    &__Column {
        padding: 0 1.5rem;
    }

    &__Title {
        margin: 0 0 1rem;
        font-family: $font-family-bold;
        font-size: 2rem;
        font-weight: $font-weight-bold;
        text-align: center;
        letter-spacing: -0.1rem;
        color: $white;

        @include media(m) {
            text-align: left;
        }
    }

    &__ButtonList {
        margin: 2rem 0 0;
        display: grid;
        gap: 1rem;
    }

    &__List {
        text-align: center;

        @include media(m) {
            margin: 0;
            margin-left: auto;
            text-align: left;
        }
    }

    &__SocialItem {
        &:not(:last-of-type) {
            margin-bottom: 0.6rem;

            @include media(m) {
                margin-bottom: 0;
            }
        }
    }

    &__SocialLink {
        font-family: $font-family-bold;
        font-weight: $font-weight-regular;
        font-size: 3rem;
        line-height: 120%;
        text-decoration: underline;
        text-decoration-thickness: 0.3rem;
        text-underline-offset: 0.3rem;
        text-decoration-color: transparent;
        color: $white;
        transition: text-decoration-color $transition-linear;

        @media (hover: hover) {
            &:hover {
                text-decoration-color: $white;
            }
        }
    }

    &__Logo {
        text-align: center;
        color: $white;

        @include media(m) {
            text-align: right;
        }
    }

    &__Text {
        margin: 7rem 0 0;
        padding: 0 1.5rem;
        font-size: 1.4rem;
        line-height: 130%;
        text-align: center;
        color: $white;

        @include media(m) {
            width: size(4);
            text-align: left;
        }

        @include media(xl) {
            width: size(3);
        }
    }

    &__Bottom {
        margin: 4rem 0 0 0;
        font-family: $font-family-regular;
        font-size: 1.2rem;
        font-weight: $font-weight-regular;
        line-height: 100%;
        text-align: center;
        color: $grey-50;
        display: grid;

        @include media(m) {
            grid-template-columns: 1fr auto;
            gap: 4rem;
        }
    }

    &__LinkList {
        margin: 2rem -1rem;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-wrap: wrap;

        @include media(m) {
            margin: -0.6rem;
            justify-content: flex-start;
        }
    }

    &__LinkItem {
        &:not(:last-of-type) {
            &::after {
                content: '|';
                margin: 0 0.4rem;
                display: inline-block;
            }
        }
    }

    &__Link {
        padding: 1rem;
        display: inline-block;

        @include media(m) {
            padding: 0.6rem;
        }

        @media (hover: hover) {
            &:hover {
                text-decoration: underline;
            }
        }
    }

    &__ConsentButton {
        padding: 1rem;
        font-family: $font-family-regular;
        font-size: 1.2rem;
        font-weight: $font-weight-regular;
        line-height: 100%;
        color: $grey-50;
        background-color: transparent;
        border: 0;
        display: inline-block;

        @include media(m) {
            padding: 0.6rem;
        }

        @media (hover: hover) {
            &:hover {
                text-decoration: underline;
                cursor: pointer;
            }
        }
    }

    &__Copyright {
        @include media(m) {
            text-align: right;
        }
    }
}
