@import 'styles/includes';

.Icon {
    $root: &;

    display: flex;
    align-self: center;
    align-content: center;
    flex-shrink: 0;
    pointer-events: none;

    svg {
        width: 100%;
        height: 100%;
    }

    &--Accordion {
        position: absolute;
        top: 3rem;
        right: 0;
    }

    &--NewsHubPagination {
        width: 20px;
        height: 20px;
        position: relative;
        top: 1px;
    }
}
