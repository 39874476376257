@import 'styles/includes';

.Button {
    $root: &;

    padding: 2rem 3.2rem;
    width: 100%;
    font-family: $font-family-bold;
    font-weight: $font-weight-bold;
    font-size: 1.6rem;
    line-height: 100%;
    text-align: center;
    color: $white;
    background-color: $black;
    border: 0;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    gap: 0.8rem;
    position: relative;
    z-index: 0;
    transition: color $transition-ease-out-expo;

    @include media(m) {
        padding: 2.2rem 3.2rem;
        width: auto;
        font-size: 2rem;
    }

    &::after {
        content: '';
        width: 0;
        height: 100%;
        background-color: $violet;
        position: absolute;
        inset: 0;
        z-index: -1;
        transition: width $transition-ease-out-expo;
    }

    @media (hover: hover) {
        &:hover {
            color: $black;

            &::after {
                width: 100%;
            }
        }
    }

    span {
        width: 1.6rem;
        height: 1.6rem;

        @include media(m) {
            width: 2rem;
            height: 2rem;
        }
    }

    &--Secondary {
        color: $black;
        background-color: $white;
    }

    &--Tertiary {
        padding: 1.9rem 3.2rem;
        color: $black;
        background-color: transparent;
        border: 0.1rem solid $black;
    }

    &--Contact {
        padding: 1.9rem 3.2rem;
        color: $white;
        background-color: transparent;
        border: 0.1rem solid $white;
    }

    &--Quaternary {
        padding: 1.9rem 3.2rem;
        font-family: $font-family-regular;
        font-size: 1.6rem;
        font-weight: $font-weight-bold;
        line-height: 100%;
        text-align: center;
        color: $white;
        background-color: $black;
        border: 0.1px solid $grey-80;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 1rem;
        transition: background-color $transition-ease-out-expo;

        @include media(m) {
            padding: 1.95rem 3.2rem;
            font-size: 1.6rem;
        }

        &::after {
            display: none;
        }

        @media (hover: hover) {
            &:hover {
                color: $white;
                background-color: $grey-80;
            }
        }

        span {
            @include media(m) {
                width: 1.6rem;
                height: 1.6rem;
            }
        }
    }

    &--Quinary {
        padding: 1.9rem 3.2rem;
        font-family: $font-family-regular;
        font-size: 1.6rem;
        font-weight: $font-weight-bold;
        line-height: 100%;
        text-align: center;
        color: $white;
        background-color: transparent;
        border: 0.1px solid $white;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 1rem;
        transition:
            color $transition-ease-out-expo,
            border-color $transition-ease-out-expo;

        @include media(m) {
            padding: 1.95rem 3.2rem;
            font-size: 1.6rem;
        }

        &::after {
            display: none;
        }

        @media (hover: hover) {
            &:hover {
                color: $black;
                border: 0.1px solid $black;
            }
        }

        span {
            @include media(m) {
                width: 1.6rem;
                height: 1.6rem;
            }
        }
    }

    &--Hero {
        background-color: $white;
        color: $black;

        @include media(xl) {
            background-color: $black;
            color: $white;
        }
    }

    &--HeroSecondary {
        color: $white;
        background-color: transparent;
        border: 1px solid $white;

        @include media(xl) {
            color: $black;
            background-color: $white;
            border: 1px solid $black;
        }
    }

    &--HeroLanding {
        color: $white;
        background-color: transparent;
        border: 1px solid $white;

        @include media(l) {
            margin-top: 1rem;
        }
    }

    &--BorderRadius {
        border-radius: 0.4rem;
    }
}
