@import 'styles/includes';

.Banner {
    $root: &;
    padding: 1.2rem 0;
    font-family: $font-family-semibold;
    font-weight: 600;
    font-size: 1.2rem;
    letter-spacing: 0.02rem;
    color: $white;
    display: none;

    &--Visible {
        display: block;
    }

    &__Container {
        @extend %container;

        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
    }

    &__Content {
        text-align: center;

        a {
            @extend %link;
            text-underline-offset: 0.2rem;
        }
    }

    &__Close {
        padding: 0 0.9rem;
        margin-right: -0.9rem;
        background-color: transparent;
        border: 0;
        position: absolute;
        right: 3rem;
        display: flex;
        color: $white;
        border-left: 1px solid rgba(255, 255, 255, 0.2);
    }
}
