@import 'styles/includes';

.Header {
    $root: &;

    width: 100%;
    position: fixed;
    z-index: 9;
    background-color: transparent;
    opacity: 0;
    transition:
        transform $transition-linear,
        opacity $transition-linear,
        background-color $transition-linear,
        color $transition-linear;

    &--Hide {
        transform: translateY(-100%);
    }

    &--Visible {
        opacity: 1;
    }

    &--Black {
        color: $white;

        &#{$root}--IsExpanded {
            @include media-max(l) {
                color: $black;
            }
        }
    }

    &--BackgroundColor {
        background-color: $white;
        color: $black;

        &#{$root}--Black {
            background-color: $black;
            color: $white;
        }

        &#{$root}--IsExpanded {
            @include media-max(l) {
                color: $black;
            }
        }
    }

    &--Newshub {
        color: $white;
        background-color: $black;

        &#{$root}--IsExpanded {
            @include media-max(l) {
                color: $black;
            }
        }
    }

    &--HomePage {
        color: $white;

        @include media(xl) {
            color: $black;
        }

        &#{$root}--IsExpanded {
            @include media-max(l) {
                color: $black;
            }
        }

        &#{$root}--BackgroundColor {
            background-color: $black;
            color: $white;

            @include media(xl) {
                background-color: $white;
                color: $black;
            }

            &#{$root}--IsExpanded {
                @include media-max(l) {
                    color: $black;
                }
            }
        }
    }

    &__Container {
        @extend %container;
        padding-top: 1.6rem;
        padding-bottom: 1.6rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 3rem;
    }

    &__Logo {
        padding: 0.234rem 0;
        padding: 0 0 0.468rem;
        flex-shrink: 0;
        display: inline-flex;
    }

    &__Toggle {
        padding: 0;
        height: 2rem;
        border: 0;
        background-color: transparent;
        position: relative;

        &::before {
            content: '';
            padding: 1.6rem;
            position: absolute;
            inset: 0;
            top: 0;
            transform: translate(-0.4rem, -0.6rem);
        }

        @include media(l) {
            display: none;
        }
    }

    &__Line {
        width: 2.4rem;
        height: 0.2rem;
        background-color: $black;
        display: block;

        #{$root}--IsExpanded & {
            visibility: hidden;
        }

        #{$root}--Black & {
            background-color: $white;
        }

        #{$root}--White & {
            background-color: $black;
        }

        #{$root}--HomePage & {
            background-color: $white;
        }

        #{$root}--Newshub & {
            background-color: $white;
        }

        &::before,
        &::after {
            content: '';
            width: 2.4rem;
            height: 0.2rem;
            background-color: $black;
            display: block;
            transition: transform $transition-ease;

            #{$root}--Black & {
                background-color: $white;
            }

            #{$root}--White & {
                background-color: $black;
            }

            #{$root}--HomePage & {
                background-color: $white;
            }

            #{$root}--Newshub & {
                background-color: $white;
            }

            #{$root}--IsExpanded &,
            #{$root}--IsExpanded:hover & {
                visibility: visible;
                background-color: $black;
            }
        }

        &::before {
            transform: translateY(-6px);

            #{$root}__Toggle:hover & {
                transform: translateY(-8px);
            }

            #{$root}--IsExpanded &,
            #{$root}--IsExpanded:hover & {
                transform: translateY(0px) rotate(45deg);
            }
        }

        &::after {
            transform: translateY(4px);

            #{$root}__Toggle:hover & {
                transform: translateY(6px);
            }

            #{$root}--IsExpanded &,
            #{$root}--IsExpanded:hover & {
                transform: translateY(-2px) rotate(-45deg);
            }
        }
    }

    &__Navigation {
        padding: 0 3rem;
        width: 100%;
        height: 100vh;
        position: fixed;
        inset: 0;
        background-color: $grey-20;
        z-index: -1;
        opacity: 0;
        visibility: hidden;
        transform: translateY(-100vh);
        transition:
            opacity $transition-linear,
            visibility $transition-linear;

        #{$root}--IsExpanded & {
            opacity: 1;
            visibility: visible;
            transform: translateY(0);
        }

        @include media(l) {
            padding: 0;
            height: 100%;
            position: relative;
            background-color: transparent;
            z-index: unset;
            opacity: 1;
            visibility: visible;
            transition: none;
            transform: translateY(0);
        }
    }

    &__List {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        @include media(l) {
            flex-direction: row;
            justify-content: flex-end;
            gap: 3rem;
        }
    }

    &__Item {
        @include media-max(l) {
            #{$root}--IsExpanded & {
                animation: slide-in 0.7s cubic-bezier(0.19, 1, 0.22, 1) both;

                @for $i from 0 through 20 {
                    &:nth-child(#{$i}) {
                        animation-delay: #{$i * 80}ms;
                    }
                }
            }
        }

        &:nth-last-child(2) {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            @include media(l) {
                justify-content: flex-start;
                flex-direction: row;
                gap: 3rem;
            }

            &::before {
                content: '';
                margin: 1rem 0;
                width: 0.1rem;
                height: 4rem;
                background-color: $grey-40;
                display: block;
                position: relative;

                @include media(l) {
                    margin: 0;
                    height: 2.4rem;
                    background-color: $grey-70;
                    display: inline-block;
                }
            }
        }
    }

    &__Link {
        padding: 1rem 0;
        font-family: $font-family-bold;
        font-size: 3.2rem;
        font-weight: $font-weight-bold;
        display: block;
        text-decoration-color: transparent;

        @media (hover: hover) {
            &:hover {
                text-decoration-color: $black;

                #{$root}--Black & {
                    text-decoration-color: $white;
                }
                #{$root}--HomePage & {
                    text-decoration-color: $white;
                }
            }
        }

        @include media(l) {
            padding: 0;
            font-family: $font-family-regular;
            font-size: 1.6rem;
            font-weight: $font-weight-regular;
            display: inline-block;
            text-decoration: underline;
            text-decoration-color: transparent;
            text-underline-offset: 0.4rem;
            text-decoration-thickness: 0.1rem;
            transition: text-decoration-color $transition-linear;
        }

        @include media(xl) {
            @media (hover: hover) {
                &:hover {
                    #{$root}--HomePage & {
                        text-decoration-color: $black;
                    }
                }
            }
        }

        &--Active {
            text-decoration: underline;
            text-decoration-color: inherit;
        }
    }

    &__Button {
        padding: 1.2rem 2.4rem;
        font-family: $font-family-bold;
        font-size: 3.2rem;
        font-weight: $font-weight-bold;
        background-color: transparent;
        border: 0.1rem solid $black;
        border-radius: 10rem;
        display: block;
        transition:
            background-color $transition-linear,
            color $transition-linear;

        @include media(l) {
            padding: 1rem 1.8rem;
            font-family: $font-family-regular;
            font-size: 1.6rem;
            font-weight: $font-weight-regular;
            display: inline-block;
        }

        @media (hover: hover) {
            &:hover {
                background-color: $black;
                color: $white;
            }
        }

        #{$root}--Newshub &,
        #{$root}--Black & {
            border-color: $white;

            @media (hover: hover) {
                &:hover {
                    background-color: $white;
                    color: $black;
                }
            }
        }

        #{$root}--IsExpanded &,
        #{$root}--White & {
            border-color: $black;

            @media (hover: hover) {
                &:hover {
                    background-color: $black;
                    color: $white;
                }
            }
        }

        #{$root}--HomePage & {
            border-color: $black;

            @media (hover: hover) {
                &:hover {
                    background-color: $white;
                    color: $black;
                }
            }

            @include media(l) {
                border-color: $white;
            }

            @include media(xl) {
                border-color: $black;
            }

            @include media(xl) {
                @media (hover: hover) {
                    &:hover {
                        background-color: $black;
                        color: $white;
                    }
                }
            }
        }
    }
}
